exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ListWrapper__wrapper___14xsJ{transform:translateY(1px);width:100%;background-color:#fff;box-shadow:0 7px 40px 0 rgba(0,0,0,0.2);border-radius:6px;padding-top:20px;padding-bottom:9px;text-align:left;animation:ListWrapper__fadein___1WT07 0.1s}@keyframes ListWrapper__fadein___1WT07{from{opacity:0}to{opacity:1}}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "ListWrapper__wrapper___14xsJ",
	"fadein": "ListWrapper__fadein___1WT07"
};