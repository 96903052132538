exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ScrollButton__scroll-button-box___9M3hD{display:flex;align-items:center;justify-content:center;width:32px;cursor:pointer;flex:none;position:absolute;height:100%;top:0;z-index:10}.ScrollButton__scroll-button-box-left___1wCDn{left:0;background-image:linear-gradient(90deg, #fff 0%, rgba(255,255,255,0) 100%)}.ScrollButton__scroll-button-box-right___7kHph{right:0;background-image:linear-gradient(270deg, #fff 0%, rgba(255,255,255,0) 100%)}.ScrollButton__scroll-button-box--disabled___1dcvR{cursor:not-allowed}.ScrollButton__scroll-button-box__arrow___3_ps_{color:#557eff;font-size:10px}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"button-width-size": "32",
	"no-scroll-padding": "12",
	"scroll-button-box": "ScrollButton__scroll-button-box___9M3hD",
	"scroll-button-box-left": "ScrollButton__scroll-button-box-left___1wCDn",
	"scroll-button-box-right": "ScrollButton__scroll-button-box-right___7kHph",
	"scroll-button-box--disabled": "ScrollButton__scroll-button-box--disabled___1dcvR",
	"scroll-button-box__arrow": "ScrollButton__scroll-button-box__arrow___3_ps_"
};