exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Section__disabled___1c43Q{pointer-events:none}.Section__hidden___1eB73{visibility:hidden}.Section__sectionWrapper___qr3pl{display:flex}.Section__sectionWrapper___qr3pl[data-border-right=true]{border-right:solid 1px #e9e9e9}.Section__sectionWrapper___qr3pl[data-border-left=true]{border-left:solid 1px #e9e9e9}.Section__sectionWrapper__content___3H3ZV{display:flex;align-items:center}.Section__section___38giG{position:relative;display:flex;color:#778195;align-items:center;justify-content:center;height:100%;user-select:none;padding:0px 20px;width:auto;animation-name:Section__section-appear___33b-g;animation-duration:0.5s;animation-fill-mode:both}@keyframes Section__section-appear___33b-g{0%{visibility:hidden;opacity:0}100%{visibility:visible;opacity:1}}.Section__section___38giG:hover{z-index:1}.Section__icon___3QGxw{height:100%;font-family:FontAwesome;font-size:14px;align-items:center;justify-content:center;display:flex}.Section__icon___3QGxw:not(:last-child){margin-right:9px}.Section__text___2IWTf{display:inline-flex}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"disabled": "Section__disabled___1c43Q",
	"hidden": "Section__hidden___1eB73",
	"sectionWrapper": "Section__sectionWrapper___qr3pl",
	"sectionWrapper__content": "Section__sectionWrapper__content___3H3ZV",
	"section": "Section__section___38giG",
	"section-appear": "Section__section-appear___33b-g",
	"icon": "Section__icon___3QGxw",
	"text": "Section__text___2IWTf"
};