exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__box-wrapper___1AX0I{padding:10px 20px;border-radius:3px;background-color:#fafbfc;border:1px solid #e9e9e9;margin-left:6px;margin-top:1px;margin-bottom:1px}.style__box-title___yrIjk{width:100%;display:flex;flex-direction:row;justify-content:space-between}.style__box-label___1-kTN{font-weight:500;color:#778195}.style__details___2EAc4{flex:1;display:flex;flex-direction:column;justify-content:space-between;font-size:14px;text-align:left}.style__details-text___2ojVn{font-size:21px;font-weight:300;color:#778195;align-items:center;display:flex;justify-content:center}\n", ""]);

// Exports
exports.locals = {
	"box-wrapper": "style__box-wrapper___1AX0I",
	"box-title": "style__box-title___yrIjk",
	"box-label": "style__box-label___1-kTN",
	"details": "style__details___2EAc4",
	"details-text": "style__details-text___2ojVn"
};