exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UiSwitcher__switch-container___3Jkcr[data-active=\"true\"] .UiSwitcher__switch-ball___1FzIL{transform:translate3d(var(--switch-space), 0, 0);background-color:#fff}.UiSwitcher__switch-ball___1FzIL{width:var(--ball-size);height:var(--ball-size)}input[type=\"checkbox\"].UiSwitcher__switch___1f19F{position:absolute;cursor:pointer;opacity:0}input[type=\"checkbox\"].UiSwitcher__switch___1f19F+div{vertical-align:middle;border-radius:999px;-webkit-transition-duration:.4s;transition-duration:.4s;-webkit-transition-property:background-color, box-shadow;transition-property:background-color, box-shadow;cursor:pointer}.UiSwitcher__onColor___2BqmA{background-color:#a3a8a5}.UiSwitcher__offColor___1pxFy{background-color:#a3a8a5}input[type=\"checkbox\"].UiSwitcher__switch___1f19F:checked+div{background-position:0 0}input[type=\"checkbox\"].UiSwitcher__switch___1f19F:checked+div{background-color:#557EFF}input[type=\"checkbox\"].UiSwitcher__switch___1f19F+div>div{float:left;border-radius:inherit;background:#fff;transition-timing-function:cubic-bezier(1, 0, 0, 1);transition-duration:0.4s;transition-property:transform, background-color;pointer-events:none;margin-top:1px;margin-left:1px}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"switch-container": "UiSwitcher__switch-container___3Jkcr",
	"switch-ball": "UiSwitcher__switch-ball___1FzIL",
	"switch": "UiSwitcher__switch___1f19F",
	"onColor": "UiSwitcher__onColor___2BqmA",
	"offColor": "UiSwitcher__offColor___1pxFy"
};