exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Chip__item___3I6s2{font-size:13px;text-align:left;color:#555;display:inline-flex;border:solid 1px #e9e9e9;background-color:#f3f3f3;border-radius:3px;align-items:center;padding:3px 6px;white-space:pre-wrap;word-wrap:break-word}.Chip__item___3I6s2>.Chip__button-remove___xY6Pq{color:#969696;font-size:10px;cursor:pointer;margin-left:5px;margin-bottom:2px}.Chip__item___3I6s2>.Chip__button-remove___xY6Pq:hover{color:#545454}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"item": "Chip__item___3I6s2",
	"button-remove": "Chip__button-remove___xY6Pq"
};