exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".header__header-wrapper___2xB27{display:flex;align-items:center}.header__header-text___2Z-KN{position:relative;color:#557eff;font-size:13px;font-weight:500;margin-left:var(--header-padding);margin-right:0.2em}.header__header-sorters___2KgT5{position:relative;display:inline-flex;margin-left:10px;flex-direction:column;justify-content:center}.header__header-sorters___2KgT5 i{font-size:12px;color:#dbdbdb;position:absolute}.header__header-sorters___2KgT5 i:first-child{top:-16px}.header__header-sorters___2KgT5 i:last-child{top:-6px}.header__header-sorters___2KgT5 i[sorted=true]{color:#557eff}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"header-wrapper": "header__header-wrapper___2xB27",
	"header-text": "header__header-text___2Z-KN",
	"header-sorters": "header__header-sorters___2KgT5"
};