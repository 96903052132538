exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LabeledCheckbox__wrapper___3qam7{display:flex;align-items:center;width:100%;cursor:pointer}.LabeledCheckbox__wrapper___3qam7.LabeledCheckbox__disabled___6MH74{cursor:default}.LabeledCheckbox__wrapper___3qam7 .LabeledCheckbox__label_wrapper___NzPrU{font-size:13px;color:#555;cursor:pointer;position:relative;bottom:1px;flex:1;min-width:0}.LabeledCheckbox__input_wrapper___3oZ6_{margin-right:10px}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "LabeledCheckbox__wrapper___3qam7",
	"disabled": "LabeledCheckbox__disabled___6MH74",
	"label_wrapper": "LabeledCheckbox__label_wrapper___NzPrU",
	"input_wrapper": "LabeledCheckbox__input_wrapper___3oZ6_"
};