exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__fileDrop___1Z_3b{width:100%;display:flex;align-items:center;justify-content:center}.style__top___31smX{padding-right:12px;padding-left:12px;display:flex;justify-content:space-between;flex-wrap:wrap}.style__bottom___3m7K_{display:flex;flex-direction:column;overflow:auto;flex:1}.style__top-inputs___1Mg5g{display:flex;flex:1;flex-direction:row;justify-content:space-between;flex-wrap:wrap}.style__input-container___ULcL-{width:291px}.style__plus-button-wrapper___2lBw4{display:flex;flex-direction:column}.style__add-buttons-container___1tIlM{display:flex;align-items:center;flex-direction:row;width:163px;justify-content:space-between}.style__scenario-button___8TDmB{width:165px;height:38px;box-shadow:0 0 20px 0 rgba(0,0,0,0.5);display:flex;align-items:center;justify-content:center}.style__actions-style___3ha_3{margin-left:20}.style__link-style___nhQva,.style__actions-style___3ha_3,.style__download-button___3h1jK{cursor:pointer;font-family:Roboto;font-size:14px;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.62;letter-spacing:normal;text-align:center;color:#eba205}.style__fileDropTarget___2O_4m{display:flex;align-items:center;justify-content:center;flex-direction:column}.style__download-button___3h1jK{color:#557eff}\n", ""]);

// Exports
exports.locals = {
	"fileDrop": "style__fileDrop___1Z_3b",
	"top": "style__top___31smX",
	"bottom": "style__bottom___3m7K_",
	"top-inputs": "style__top-inputs___1Mg5g",
	"input-container": "style__input-container___ULcL-",
	"plus-button-wrapper": "style__plus-button-wrapper___2lBw4",
	"add-buttons-container": "style__add-buttons-container___1tIlM",
	"scenario-button": "style__scenario-button___8TDmB",
	"actions-style": "style__actions-style___3ha_3",
	"link-style": "style__link-style___nhQva",
	"download-button": "style__download-button___3h1jK",
	"fileDropTarget": "style__fileDropTarget___2O_4m"
};