exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Header__disabled___Gh7Xk{pointer-events:none}.Header__hidden___JXyng{visibility:hidden}.Header__leftHeader___3ARZV{display:flex;flex:1;justify-content:left;align-items:center;font-size:16px;text-align:left;overflow:hidden}.Header__rightHeader___3MyNH{display:flex;justify-content:right;overflow:hidden}.Header__collapsibleHeader___1PBe-{display:flex;flex-direction:row;justify-content:space-between;min-height:48px;padding:8px 21px;color:#778195;letter-spacing:-0.2px}.Header__collapsibleHeader___1PBe-:not([editable=true]):hover{cursor:pointer}.Header__collapsibleHeader___1PBe-[toggleable=false]:not([data-title-only=\"true\"]){padding-right:0}.Header__headerIcon___2CENw{font-family:FontAwesome;font-size:20px;text-align:left;color:#79c2db}.Header__headerTitle___2WWue{height:100%;font-size:16px;text-align:left}.Header__headerToggle___3u_WM{padding-left:20px;font-family:FontAwesome;border:none;color:#557eff;transform:rotateX(0);transition:0.5s transform}.Header__toggleWrapper___14d-W{border-left:solid 1px #e9e9e9;display:flex;justify-content:center;align-items:center;font-size:12px}.Header__headerToggle___3u_WM[rotate=true]{transform:rotateX(180deg)}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"disabled": "Header__disabled___Gh7Xk",
	"hidden": "Header__hidden___JXyng",
	"leftHeader": "Header__leftHeader___3ARZV",
	"rightHeader": "Header__rightHeader___3MyNH",
	"collapsibleHeader": "Header__collapsibleHeader___1PBe-",
	"headerIcon": "Header__headerIcon___2CENw",
	"headerTitle": "Header__headerTitle___2WWue",
	"headerToggle": "Header__headerToggle___3u_WM",
	"toggleWrapper": "Header__toggleWrapper___14d-W"
};