exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Dropdown__wrapper___qY5oB{width:100%;position:relative;line-height:18px}.Dropdown__wrapper___qY5oB[data-disabled=\"true\"]{pointer-events:none}.Dropdown__selection-input___27B68{width:53px;font-size:12px;padding:3px 5px 0px;height:27px}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "Dropdown__wrapper___qY5oB",
	"selection-input": "Dropdown__selection-input___27B68"
};