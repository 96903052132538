exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__modal-content___3hhRI{background-color:#fefefe;margin:auto;padding:20px;border:1px solid #888;width:80%;flex:1}.style__modal___3s9M0{display:flex;position:fixed;z-index:999999;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#000;background-color:rgba(0,0,0,0.4)}.style__exit-icon___PZxy5{cursor:pointer;font-size:12px;color:#c2c2c2}.style__icon-wrapper___3-lYG{display:flex;width:100%;flex-direction:row;justify-content:flex-end}.style__icon-wrapper___3-lYG svg{position:absolute;z-index:2;font-size:22px;color:#545454}\n", ""]);

// Exports
exports.locals = {
	"modal-content": "style__modal-content___3hhRI",
	"modal": "style__modal___3s9M0",
	"exit-icon": "style__exit-icon___PZxy5",
	"icon-wrapper": "style__icon-wrapper___3-lYG"
};