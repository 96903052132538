exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".pagination__pagination___2KvKo{display:flex;justify-content:flex-end;align-items:baseline;cursor:default;padding-right:20px;margin:16px 0;user-select:none}.pagination__description___2S5R5{position:relative;line-height:1.5;font-size:12px;color:#778195}.pagination__description___2S5R5:not(:hover){overflow:hidden}.pagination__switcher-reference___2JEVP{pointer-events:none;display:inline-block;position:absolute}.pagination__page-switcher___3CX7k{opacity:0;transition:opacity 0.2s;pointer-events:none;position:absolute;right:0;border-radius:3px;background-color:#fff;box-shadow:0 7px 40px 0 rgba(0,0,0,0.2);text-align:right;padding:16px;white-space:nowrap;color:#778195}.pagination__page-switcher___3CX7k[position='top']{bottom:-16px}.pagination__page-switcher___3CX7k[position='bottom']{top:-17px;right:-16px}.pagination__page-switcher___3CX7k.pagination__hover___3jr_T{opacity:1;pointer-events:all}.pagination__page-switcher___3CX7k div{line-height:20px;cursor:pointer}.pagination__page-switcher___3CX7k [currentpage=true]{font-weight:bold}.pagination__arrows___Q1m49{display:inline-block}.pagination__arrows___Q1m49 i{cursor:default;font-size:23px;color:#dbdbdb}.pagination__arrows___Q1m49 i:first-child{margin-right:30px;margin-left:30px}.pagination__arrows___Q1m49 i[enabled=true]{color:#555;cursor:pointer}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"pagination": "pagination__pagination___2KvKo",
	"description": "pagination__description___2S5R5",
	"switcher-reference": "pagination__switcher-reference___2JEVP",
	"page-switcher": "pagination__page-switcher___3CX7k",
	"hover": "pagination__hover___3jr_T",
	"arrows": "pagination__arrows___Q1m49"
};