exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Filter__filter-wrapper___2mtnc{padding:0 20px;margin-bottom:15px}.Filter__filter-wrapper___2mtnc .Filter__filter-input___xk5N0{width:calc(100% - 2px);border:0;border-radius:0;padding:0 0 15px;border-bottom:1px solid #557eff;font-size:13px}.Filter__filter-wrapper___2mtnc .Filter__filter-input___xk5N0:focus{box-shadow:0 0 0 2px rgba(68,130,255,0);outline:none}.Filter__filter-wrapper___2mtnc .Filter__filter-input___xk5N0::placeholder{color:#c2c2c2}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"filter-wrapper": "Filter__filter-wrapper___2mtnc",
	"filter-input": "Filter__filter-input___xk5N0"
};