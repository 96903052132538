exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Arrows__arrow___2uBpo{display:flex !important;background:white;justify-content:center;align-items:center;flex:1;width:18px;text-align:center;font-size:12px !important;border-left:none;cursor:not-allowed;color:#c2c2c2}.Arrows__arrow___2uBpo:active{background-color:#dce0e0}.Arrows__arrow--disabled___2fqay{background:#fafafa;color:#c2c2c2}.Arrows__up-arrow___ngL2q{border-top-right-radius:3px;border-bottom:none}.Arrows__enabled___2_wJE{color:#557eff;cursor:pointer}.Arrows__down-arrow___1xVd0{border-top:1px solid #e9e9e9;border-bottom-right-radius:3px}.Arrows__arrows-wrapper___3qNes{display:flex;flex-direction:column}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"arrow": "Arrows__arrow___2uBpo",
	"arrow--disabled": "Arrows__arrow--disabled___2fqay",
	"up-arrow": "Arrows__up-arrow___ngL2q",
	"enabled": "Arrows__enabled___2_wJE",
	"down-arrow": "Arrows__down-arrow___1xVd0",
	"arrows-wrapper": "Arrows__arrows-wrapper___3qNes"
};