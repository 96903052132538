exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__main___1bXv4{width:100%;height:100%;margin:20px auto 0;text-align:center}.style__panel___3K4FN{display:inline-block;width:650px;text-align:left}.style__header___3Rdzz{text-align:center;padding:5px;color:#1d7ab4;font-size:36px}.style__line___j1Wow{display:flex;flex-direction:row;font-size:18px}.style__create-button___6GUcA{width:120px;height:35px !important;margin-bottom:10px;padding:0px !important}\n", ""]);

// Exports
exports.locals = {
	"main": "style__main___1bXv4",
	"panel": "style__panel___3K4FN",
	"header": "style__header___3Rdzz",
	"line": "style__line___j1Wow",
	"create-button": "style__create-button___6GUcA"
};