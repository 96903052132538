exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Input__input___5vxBY{transition:all 0.2s;font-size:13px;width:100%;padding:0 5px;border-radius:6px;border:1px solid #e9e9e9;height:var(--input-height)}.Input__input___5vxBY.Input__input--error___1OXAb{border-color:#f9453e}.Input__input___5vxBY.Input__input--disabled___1YklX{background:#fafafa}.Input__input___5vxBY:hover{border-color:rgba(121,194,219,0.27)}.Input__input___5vxBY:active,.Input__input___5vxBY:focus,.Input__input___5vxBY:focus-within{outline:none;border-color:#557eff;box-shadow:inset 0 0 5px 0 rgba(13,145,189,0.25)}.Input__input___5vxBY::placeholder{color:#a8a8a8}.Input__input___5vxBY[type=number]{-moz-appearance:textfield}.Input__input___5vxBY[type=number]::-webkit-inner-spin-button,.Input__input___5vxBY[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"input": "Input__input___5vxBY",
	"input--error": "Input__input--error___1OXAb",
	"input--disabled": "Input__input--disabled___1YklX"
};