exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MultiSelect__wrapper___1n7bt{width:100%;position:relative;line-height:18px}.MultiSelect__wrapper___1n7bt[data-disabled=\"true\"]{pointer-events:none}.MultiSelect__overflow-icon___1bL64{padding-top:2px;font-size:17px;padding-right:8px;color:#0d91bd}.MultiSelect__selection-input___234O7{width:53px;font-size:12px;padding:3px 5px 0px;height:27px}.MultiSelect__input-item___2xrg_{font-size:12px;height:25px;margin-right:4px;margin-bottom:calc((var(--multiple-select-height) - 27px) / 2)}.MultiSelect__list___2p1zM{transform:translateY(1px);z-index:99999;width:100%;min-height:93px;background-color:#fff;box-shadow:0 7px 40px 0 rgba(0,0,0,0.2);border-radius:3px;position:absolute;padding-bottom:9px;animation:MultiSelect__fadein___1v0I0 0.1s}@keyframes MultiSelect__fadein___1v0I0{from{opacity:0}to{opacity:1}}.MultiSelect__ellipsis___1WmLm{height:var(--multiple-select-height)}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "MultiSelect__wrapper___1n7bt",
	"overflow-icon": "MultiSelect__overflow-icon___1bL64",
	"selection-input": "MultiSelect__selection-input___234O7",
	"input-item": "MultiSelect__input-item___2xrg_",
	"list": "MultiSelect__list___2p1zM",
	"fadein": "MultiSelect__fadein___1v0I0",
	"ellipsis": "MultiSelect__ellipsis___1WmLm"
};