exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TitleInput__title_wrapper___1Wj3F{display:grid;grid-row-gap:5px;flex:1}.TitleInput__title___3lldl{font-size:13px;text-align:left;font-weight:500;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;color:#557eff;display:block}.TitleInput__title___3lldl.TitleInput__title--disabled___e9Tga{color:#c2c2c2}.TitleInput__title___3lldl.TitleInput__title--alert___1ij0r{color:#f9453e}.TitleInput__suffix___1w8E2{color:#778195;font-weight:300}.TitleInput__prefix___e9Rqk{font-weight:300}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"title_wrapper": "TitleInput__title_wrapper___1Wj3F",
	"title": "TitleInput__title___3lldl",
	"title--disabled": "TitleInput__title--disabled___e9Tga",
	"title--alert": "TitleInput__title--alert___1ij0r",
	"suffix": "TitleInput__suffix___1w8E2",
	"prefix": "TitleInput__prefix___e9Rqk"
};