exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NumericInput__InputText___3lGx4{font-weight:400;font-size:1rem;color:#555;letter-spacing:normal;line-height:1.6183rem}.NumericInput__InputText--placeholder___3yHcM{font-weight:400;font-size:1rem;color:#555;letter-spacing:normal;color:#a8a8a8}.NumericInput__InputText--view___Ede7V{font-weight:400;font-size:1rem;color:#555;letter-spacing:normal;color:#778195}.NumericInput__input-counter___p1sh6{font-weight:400;font-size:1rem;color:#555;letter-spacing:normal;flex:1;width:0;height:100%;border-radius:3px 0 0 3px;background-color:transparent;text-align:center;border:none;border-right:1px solid #e9e9e9}.NumericInput__input-counter___p1sh6:active,.NumericInput__input-counter___p1sh6:focus{outline:none}.NumericInput__input-counter-wrapper___2Zvu6{display:flex;overflow:hidden;width:var(--number-input-width);height:var(--number-input-height);background:white;border-radius:6px;border:1px solid #e9e9e9}.NumericInput__input-counter-wrapper___2Zvu6[data-error=\"true\"]{border-color:#f9453e}.NumericInput__input-counter-wrapper___2Zvu6:hover{border-color:rgba(121,194,219,0.27)}.NumericInput__input-counter-wrapper___2Zvu6:focus-within{border-color:#557eff;box-shadow:inset 0 0 5px 0 rgba(13,145,189,0.25)}.NumericInput__input-counter-wrapper___2Zvu6[data-disabled=\"true\"]{pointer-events:none}.NumericInput__input-counter-wrapper___2Zvu6[data-disabled=\"true\"] .NumericInput__input-counter___p1sh6{background:#fafafa;color:#c2c2c2}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"InputText": "NumericInput__InputText___3lGx4",
	"InputText--placeholder": "NumericInput__InputText--placeholder___3yHcM",
	"InputText--view": "NumericInput__InputText--view___Ede7V",
	"input-counter": "NumericInput__input-counter___p1sh6",
	"input-counter-wrapper": "NumericInput__input-counter-wrapper___2Zvu6"
};