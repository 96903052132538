exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__wrapper___3Jz2R{display:flex;width:100%;height:97px;border-radius:6px;box-shadow:0 1px 9px 0 rgba(0,0,0,0.05);background-color:#ffffff;align-items:center;padding:15px}.style__input___2bDOE{max-width:99%}.style__title-wrapper___2x3-5{flex:1;display:flex}.style__button___1L8OG{margin-top:23px;width:100px;height:35px !important}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "style__wrapper___3Jz2R",
	"input": "style__input___2bDOE",
	"title-wrapper": "style__title-wrapper___2x3-5",
	"button": "style__button___1L8OG"
};