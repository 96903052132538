exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TextArea__textarea___2O1UJ{transition:border 0.2s, background 0.2s, box-shadow 0.2s;font-size:13px;width:100%;padding:0 5px;padding-top:8px;padding-bottom:8px;border-radius:6px;border:1px solid #e9e9e9;height:var(--textarea-height);min-height:var(--textarea-height);resize:none}.TextArea__textarea___2O1UJ.TextArea__textarea--error___38R4Y{border-color:#f9453e}.TextArea__textarea___2O1UJ.TextArea__textarea--disabled___16vjV{background:#fafafa}.TextArea__textarea___2O1UJ:hover{border-color:rgba(121,194,219,0.27)}.TextArea__textarea___2O1UJ:active,.TextArea__textarea___2O1UJ:focus,.TextArea__textarea___2O1UJ:focus-within{outline:none;border-color:#557eff;box-shadow:inset 0 0 5px 0 rgba(13,145,189,0.25)}.TextArea__textarea___2O1UJ::placeholder{color:#a8a8a8}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"textarea": "TextArea__textarea___2O1UJ",
	"textarea--error": "TextArea__textarea--error___38R4Y",
	"textarea--disabled": "TextArea__textarea--disabled___16vjV"
};