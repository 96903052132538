exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".InkedTabsBar__tabs-container___2tHMK{display:inline-block;white-space:nowrap;position:relative;transition:transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)}.InkedTabsBar__active-tab-ink___1O2hg{border-bottom:3px solid #557eff;transition:transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"button-width-size": "32",
	"no-scroll-padding": "12",
	"tabs-container": "InkedTabsBar__tabs-container___2tHMK",
	"active-tab-ink": "InkedTabsBar__active-tab-ink___1O2hg"
};