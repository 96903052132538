exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Body__disabled___3smV8{pointer-events:none}.Body__hidden___2In3S{visibility:hidden}.Body__collapsibleBody___YrO9U{border-top:solid 1px #e9e9e9;overflow:visible;max-height:var(--content-height);opacity:1;transition:opacity 0.5s ease-in-out, max-height 0.5s ease-in-out}.Body__collapsibleBody--hidden___HY_SU{max-height:0;opacity:0;overflow:hidden;transition:opacity 0.5s ease-in-out, max-height 0.5s ease-in-out}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"disabled": "Body__disabled___3smV8",
	"hidden": "Body__hidden___2In3S",
	"collapsibleBody": "Body__collapsibleBody___YrO9U",
	"collapsibleBody--hidden": "Body__collapsibleBody--hidden___HY_SU"
};