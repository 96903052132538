exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".configurationColumn__header-time___1Yfqx{word-wrap:break-word !important;text-overflow:initial !important;overflow:visible !important;white-space:pre-wrap;line-height:1.5em}.configurationColumn__action-style___3U9Yx{color:#557eff;cursor:pointer;height:46px;display:flex;align-items:center;justify-content:center}.configurationColumn__center-flex___1si7J{display:flex;align-items:center;justify-content:center}.configurationColumn__disabled-button___2kx4B{color:#b4c9d3;cursor:not-allowed}.configurationColumn__icon___1-lGy{font-size:20px}.configurationColumn__notes___1RZL2 p{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:164px}\n", ""]);

// Exports
exports.locals = {
	"header-time": "configurationColumn__header-time___1Yfqx",
	"action-style": "configurationColumn__action-style___3U9Yx",
	"center-flex": "configurationColumn__center-flex___1si7J",
	"disabled-button": "configurationColumn__disabled-button___2kx4B",
	"icon": "configurationColumn__icon___1-lGy",
	"notes": "configurationColumn__notes___1RZL2"
};