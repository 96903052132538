exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MultiValueInput__wrapper___3GhKB{width:100%;position:relative;line-height:18px}.MultiValueInput__wrapper___3GhKB:focus,.MultiValueInput__wrapper___3GhKB:focus-within{border-color:#557eff;box-shadow:inset 0 0 5px 0 rgba(13,145,189,0.25)}.MultiValueInput__wrapper___3GhKB{border-radius:6px;border:1px solid #e9e9e9;width:100%;background-color:#fff;display:flex;flex-flow:row wrap;align-items:center;padding:0 5px 3px 5px;position:relative;min-height:35px}.MultiValueInput__wrapper___3GhKB>.MultiValueInput__list-item___qNGvi{margin-top:3px;margin-right:5px}.MultiValueInput__wrapper___3GhKB[disable=true]{pointer-events:none}.MultiValueInput__placeholder___2m8MW{color:#a8a8a8;pointer-events:none;position:absolute;top:50%;transform:translateY(-50%)}.MultiValueInput__input-field___1S2-B{transform:translateY(2px);margin:0;border:none;background-color:transparent;box-shadow:none;height:25px;flex:1}.MultiValueInput__input-field___1S2-B:focus,.MultiValueInput__input-field___1S2-B:active{outline:none;box-shadow:none}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "MultiValueInput__wrapper___3GhKB",
	"list-item": "MultiValueInput__list-item___qNGvi",
	"placeholder": "MultiValueInput__placeholder___2m8MW",
	"input-field": "MultiValueInput__input-field___1S2-B"
};