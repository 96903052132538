exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Popover__ref-wrapper___1AqEL{z-index:999999}.Popover__ref-wrapper___1AqEL:not(.Popover__ref-wrapper--pointerEvents___Cyhsi){pointer-events:none}.Popover__popper___1uZIz{position:relative;border:0 solid;box-shadow:0 3px 40px 0 rgba(0,0,0,0.28);border-radius:6px;z-index:999999}.Popover__popper___1uZIz .Popover__popper__arrow___3WifB{width:0;height:0;border-style:solid;position:absolute}.Popover__popper___1uZIz .Popover__popper__content___2BE35{background-color:white;border-radius:6px}.Popover__popper___1uZIz[data-placement^=\"top\"]{margin-bottom:calc(var(--popper-offset) + 5px)}.Popover__popper___1uZIz[data-placement^=\"top\"] .Popover__popper__arrow___3WifB{border-width:5px 5px 0 5px;border-color:white transparent transparent transparent;bottom:-5px;left:calc(50% - 5px);margin-top:0;margin-bottom:0}.Popover__popper___1uZIz[data-placement^=\"bottom\"]{margin-top:calc(var(--popper-offset) + 5px)}.Popover__popper___1uZIz[data-placement^=\"bottom\"] .Popover__popper__arrow___3WifB{border-width:0 5px 5px 5px;border-color:transparent transparent white transparent;top:-5px;left:calc(50% - 5px);margin-top:0;margin-bottom:0}.Popover__popper___1uZIz[data-placement^=\"right\"]{margin-left:calc(var(--popper-offset) + 5px)}.Popover__popper___1uZIz[data-placement^=\"right\"] .Popover__popper__arrow___3WifB{border-width:5px 5px 5px 0;border-color:transparent white transparent transparent;left:-5px;top:calc(50% - 5px);margin-left:0;margin-right:0}.Popover__popper___1uZIz[data-placement^=\"left\"]{margin-right:calc(var(--popper-offset) + 5px)}.Popover__popper___1uZIz[data-placement^=\"left\"] .Popover__popper__arrow___3WifB{border-width:5px 0 5px 5px;border-color:transparent transparent transparent white;right:-5px;top:calc(50% - 5px);margin-left:0;margin-right:0}.Popover__popper___1uZIz[data-x-out-of-boundaries]{display:none}\n", ""]);

// Exports
exports.locals = {
	"ref-wrapper": "Popover__ref-wrapper___1AqEL",
	"ref-wrapper--pointerEvents": "Popover__ref-wrapper--pointerEvents___Cyhsi",
	"popper": "Popover__popper___1uZIz",
	"popper__arrow": "Popover__popper__arrow___3WifB",
	"popper__content": "Popover__popper__content___2BE35"
};