exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Checkbox__checkbox___2AreI{width:14px;height:14px;border-radius:2px;flex-shrink:0;flex-grow:0;position:relative;cursor:pointer;font-size:12px}.Checkbox__checkbox___2AreI input[type='checkbox']{cursor:inherit;opacity:0;width:14px;height:14px;margin:0;position:absolute;left:0;top:0}.Checkbox__checkbox___2AreI.Checkbox__disabled___3NQZZ{cursor:default;background-color:#e9e9e9}.Checkbox__checkbox--checked___39Epj{display:flex;justify-content:center;align-items:center;background:#557eff;color:#fff}.Checkbox__checkbox--checked___39Epj.Checkbox__disabled___3NQZZ{color:#c2c2c2}.Checkbox__checkbox--unchecked___3njuv{border:solid 1px #557eff;background-color:#fff}.Checkbox__checkbox--unchecked___3njuv.Checkbox__disabled___3NQZZ{border:solid 1px #dbdbdb}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"checkbox": "Checkbox__checkbox___2AreI",
	"disabled": "Checkbox__disabled___3NQZZ",
	"checkbox--checked": "Checkbox__checkbox--checked___39Epj",
	"checkbox--unchecked": "Checkbox__checkbox--unchecked___3njuv"
};