exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".transitions__fade-enter___2ufjC{opacity:0}.transitions__fade-enter--active___2L18F{opacity:1;transition:opacity 200ms ease-out}.transitions__fade-exit___2-0nF{opacity:1}.transitions__fade-exit--active___oKzSB{opacity:0;pointer-events:none;transition:opacity 200ms ease-out}\n", ""]);

// Exports
exports.locals = {
	"fade-enter": "transitions__fade-enter___2ufjC",
	"fade-enter--active": "transitions__fade-enter--active___2L18F",
	"fade-exit": "transitions__fade-exit___2-0nF",
	"fade-exit--active": "transitions__fade-exit--active___oKzSB"
};