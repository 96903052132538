exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NumericPagination__wrapper___342EK{display:flex;align-items:center;justify-content:space-between;height:40px;font-size:12px;color:#778195;padding:20px}.NumericPagination__counter___1i9T-{margin-right:10px;margin-left:10px}.NumericPagination__btn-text___2k4UE{color:#c2c2c2;font-size:13px;cursor:not-allowed;user-select:none}.NumericPagination__btn-enabled___aIzC8{color:#557eff;cursor:pointer}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "NumericPagination__wrapper___342EK",
	"counter": "NumericPagination__counter___1i9T-",
	"btn-text": "NumericPagination__btn-text___2k4UE",
	"btn-enabled": "NumericPagination__btn-enabled___aIzC8"
};