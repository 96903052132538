exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".get-configuration__card-wrapper___14TWy{margin-bottom:80px}.get-configuration__action-style___1pwBP{color:#557eff;cursor:pointer;height:46px;display:flex;align-items:center}.get-configuration__disabled-button___26dr3{color:#b4c9d3;cursor:not-allowed}.get-configuration__icon___29egC{font-size:20px}.get-configuration__configuration-item-wrapper___t_XZt{display:flex;flex-direction:row;justify-content:space-between;align-items:center;flex:1}\n", ""]);

// Exports
exports.locals = {
	"card-wrapper": "get-configuration__card-wrapper___14TWy",
	"action-style": "get-configuration__action-style___1pwBP",
	"disabled-button": "get-configuration__disabled-button___26dr3",
	"icon": "get-configuration__icon___29egC",
	"configuration-item-wrapper": "get-configuration__configuration-item-wrapper___t_XZt"
};