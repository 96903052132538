exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__page___1fdCH{height:100%;overflow:auto;background-color:#f1f3f6}.style__content___29u-N{margin-top:55px;margin-left:20px;margin-right:5px}.style__title___lTMYF{height:24px;font-size:20px;font-weight:900;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;color:#555555}.style__description___qLI0r{max-width:644px;min-height:46px;font-size:14px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.62;letter-spacing:normal;color:#778195;margin-bottom:43px;margin-top:20px}\n", ""]);

// Exports
exports.locals = {
	"page": "style__page___1fdCH",
	"content": "style__content___29u-N",
	"title": "style__title___lTMYF",
	"description": "style__description___qLI0r"
};