exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__top___22y3X{border-bottom:1px solid #e9e9e9;padding:10px 20px;display:flex;justify-content:space-between}.style__bottom___2GxoV{padding:10px 20px 10px 0px;display:flex;justify-content:flex-start;overflow:auto;height:50%}.style__top-inputs___33fgu{display:flex;flex-direction:row;flex-wrap:wrap;flex:1;justify-content:space-between}.style__input-container___3Rktf{display:flex;justify-content:space-between;width:350px;align-items:center}.style__plus-button-wrapper___113R5{display:flex;flex-direction:column}.style__add-buttons-container___7flwh{display:flex;align-items:center;flex-direction:row;width:163px;justify-content:space-between}.style__scenario-button___wztVF{width:165px;height:38px;box-shadow:0 0 20px 0 rgba(0,0,0,0.5);display:flex;align-items:center;justify-content:center}\n", ""]);

// Exports
exports.locals = {
	"top": "style__top___22y3X",
	"bottom": "style__bottom___2GxoV",
	"top-inputs": "style__top-inputs___33fgu",
	"input-container": "style__input-container___3Rktf",
	"plus-button-wrapper": "style__plus-button-wrapper___113R5",
	"add-buttons-container": "style__add-buttons-container___7flwh",
	"scenario-button": "style__scenario-button___wztVF"
};